/* Blog Post Page Main Container */
body{
  font-family: "Albert Sans", sans-serif;
}

.blog-post-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Always place content at the top */
  gap: 40px;
  padding: 80px 20px;
  background: #1a1a16;
  color: white;
  min-height: 100vh;
  position: relative; /* Position relative for absolute child positioning */
}

pre {
  background-color: #2d2d2d;
  color: white;
  padding: 15px;
  border-radius: 8px;
  font-family: 'Fira Code', monospace;
  overflow-x: auto;
}

code {
  color: #00d1ff;
}

/* Back Button */
.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: white;
  background: #fa4b05;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.back-btn:hover {
  transform: translateY(-5px);
  background: #d13d02;
}

/* Blog Post Content Container */
.blog-post-container {
  background: rgba(255, 255, 255, 0.05);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  max-width: 900px;
  text-align: left;
  width: 100%; /* Ensure container width is 100% of the screen */
  box-sizing: border-box; /* Include padding in width calculations */
}

/* Blog Post Title */
.blog-post-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
}

/* Blog Post Content */
.blog-post-content {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #dcdcdc;
}
/* Ensure images are responsive and not cropped */
.blog-post-content img {
  max-width: 100%; /* Ensures images fit within the container */
  height: auto; /* Maintains the aspect ratio */
  display: block; /* Removes any inline spacing */
  margin: 20px 0; /* Adds some spacing around the images */
  border-radius: 10px; /* Optional: Add some rounding to images if desired */
}


/* Responsive Styles */
@media (max-width: 768px) {
  .blog-post-page {
    padding: 60px 15px; /* Adjust padding for medium-sized screens */
  }

  .blog-post-container {
    padding: 30px; /* Reduce padding for medium screens */
  }

  .blog-post-title {
    font-size: 2rem;
  }

  .blog-post-content {
    font-size: 1rem;
  }

  .back-btn {
    top: 15px;
    left: 15px;
    font-size: 1rem;
    padding: 8px 15px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .blog-post-page {
    padding: 40px 10px; /* Adjust padding for small screens */
  }

  .blog-post-container {
    padding: 15px;
    max-width: 100%; /* Ensure container stretches to full screen width */
    box-sizing: border-box;
    padding-left: 15px; /* Ensure padding on the left */
    padding-right: 15px; /* Ensure padding on the right */
  }

  .blog-post-title {
    font-size: 1.8rem;
  }

  .blog-post-content {
    font-size: 0.9rem;
  }

  .back-btn {
    top: 10px;
    left: 10px;
    font-size: 0.9rem;
    padding: 8px 10px; /* Adjust padding for very small screens */
  }
}
