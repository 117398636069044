/* Floating button style */
.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #03738C;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    z-index: 1000;
  }
  
  .floating-button img {
    width: 30px;
    height: 30px;
  }
  
  /* Modal background overlay (semi-transparent) */
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  /* Modal content with custom style */
  .modal-content {
    background: linear-gradient(270deg, #012840, #03738C 110%);
    padding: 20px;
    border-radius: 20px;
    width: 300px;
    position: relative;
    margin-right: 20px;
    margin-bottom: 80px;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }
  
  .modal-content.active {
    transform: translateY(0);
  }
  
  /* Close button style */
  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
    color: white;
    background: none;
    border: none;
    font-weight: bold;
    
  }
  
  /* Styling for the contact modal form */
  .contact-modal-form h1 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 1.5rem;
    background: linear-gradient(270deg, #ffffff 30%, #14C5D9 110%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .contact-modal-form label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    color: white;
  }
  
  .contact-modal-form input,
  .contact-modal-form textarea {
    width: 94%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    resize: none;
  }
  
  .contact-modal-form input::placeholder,
  .contact-modal-form textarea::placeholder {
    color: #ccc;
  }
  
  .contact-modal-form button {
    width: 100%;
    padding: 12px;
    background-color: #14C5D9;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-modal-form button:hover {
    background-color: #03738C;
  }
  
  /* Media Queries */
  
  /* For tablet devices */
  @media (max-width: 768px) {
    .modal-content {
      width: 250px;
      margin-right: 15px;
      margin-bottom: 70px;
      padding: 15px;
      border-radius: 15px;
    }
    
    .close-button {
      font-size: 22px;
      top: 8px;
      right: 12px;
    }
  
    .contact-modal-form h2 {
      font-size: 1.4rem;
    }
  
    .contact-modal-form input,
    .contact-modal-form textarea {
      padding: 8px;
      font-size: 0.9rem;
    }
  
    .contact-modal-form button {
      padding: 10px;
    }
  }
  
  /* For mobile devices */
  @media (max-width: 500px) {
    .modal-background {
      justify-content: center;
      align-items: center; /* Center the modal on smaller screens */
    }
  
    .modal-content {
      width: 90%;
      margin: 0; /* Remove extra margins for mobile */
      padding: 15px;
      border-radius: 15px;
    }
  
    .close-button {
      font-size: 20px;
      top: 5px;
      right: 10px;
    }
  
    .contact-modal-form h2 {
      font-size: 1.2rem;
    }
  
    .contact-modal-form input,
    .contact-modal-form textarea {
      padding: 8px;
      font-size: 0.85rem;
    }
  
    .contact-modal-form button {
      padding: 10px;
      font-size: 0.95rem;
    }
  }
  
  /* For larger screens (desktop) */
  @media (min-width: 1200px) {
    .modal-content {
      width: 350px;
      padding: 25px;
      margin-bottom: 100px;
    }
  
    .contact-modal-form h2 {
      font-size: 1.6rem;
    }
  
    .close-button {
      font-size: 26px;
    }
  }
  
