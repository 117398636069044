body{
    font-family: "Albert Sans", sans-serif;
}
.footer{
    margin: 50px 170px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.footer-top{
    display: flex;
    justify-content: space-between;

}
.footer-top-left p{
    font-size: 20px;
    max-width: 400px;
}
.footer-top-right{
    display: flex;
    align-items: center;
    gap: 35px;
}
.footer-email-input{
    display: flex;
    gap: 30px;
    padding: 20px 30px;
    border-radius: 50px;
    background: #32323B;
    padding-right: 80px;
}
.footer-email-input input{
    outline: none;
    border: none;
    background: transparent;
    color: #a0a0a0;
    font-family: Outfit;
    font-size: 18px;
}
.footer-subscribe{
    font-size: 20px;
    padding: 22px 50px;
    border-radius: 50px;
    background: linear-gradient(264deg, #DF8908 -5.09%, #B415FF 106.28%);
    cursor: pointer;
    transition: 0.3s;
}
.footer-bottom{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}
.footer-bottom-right{
    display: flex;
    gap: 50px;
    margin-bottom: 50px;
}
.footer-email-input:hover{
    border: 2px solid white;
}
.footer-subscribe:hover{
    transform: scale(1.1);
    transition: 0.3s;
}
.footer-logo {
    width: 250px;
    height: auto;
}
.footer-bottom-right .Icon {
    text-decoration: none;  /* Removes the underline */
    color: inherit;         /* Inherit the color from the parent (or specify a custom color) */
    font-size: 24px;        /* Adjust the size of the icons */
    margin-right: 10px;     /* Add some space between icons */
    transition: color 0.3s ease; /* Optional: Add a transition for color change on hover */
}

.footer-bottom-right .Icon:hover {
    color: #03738C; /* Change to your desired hover color */
}
.footer-bottom-right p {
    margin: 0;
    font-size: 18px;
    color: inherit;
  }
  
  .footer-bottom-right .footer-link {
    text-decoration: none; /* Remove the underline */
    color: inherit; /* Ensure it inherits the color of the surrounding text */
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .footer-bottom-right .footer-link:hover {
    color: #03738C; /* Change the color on hover */
  }
  
  /* Make sure the <Link> inside <p> behaves as text */
  .footer-bottom-right p a {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit text color */
  }
  
  .footer-bottom-right p a:hover {
    color: #03738C; /* Hover color */
  }
/* Sliding Panel */
/* Sliding Panel */
.sliding-panel {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 700px;
    height: 100%;
    background: white;
    color: black;
    padding: 40px;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
    transform: translateX(100%); /* Initially off-screen */
    transition: transform 0.4s ease-in-out; /* Smooth sliding transition */
    z-index: 1000;
    overflow-y: auto; /* Enable scrolling if the content is long */
  }
  
  .sliding-panel.show {
    transform: translateX(0); /* Slide into view */
  }
  
  .sliding-panel-content {
    padding: 20px;
  }
  
  .close-btn-footer {
    background: none;
    border: none;
    color: #ff4747;
    font-size: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  
  .close-btn-footer:hover {
    color: #ff4747;
  }
.sliding-panel h2{
    color: black;
}  
  

@media (max-width: 768px) {
    .footer {
        margin: 30px 20px;
        gap: 20px;
    }

    .footer-top {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .footer-top-left {
        text-align: center;
    }

    .footer-logo {
        width: 200px;
    }

    .footer-top-right {
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 20px;
    }

    .footer-email-input {
        padding: 15px 20px;
        padding-right: 40px;
        width: 100%;
        justify-content: center;
    }

    .footer-subscribe {
        width: 100%;
        text-align: center;
        padding: 15px 0;
        font-size: 18px;
    }

    .footer-bottom {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-align: center;
        text-decoration: none;
    }

    .footer-bottom-right {
        flex-direction: row;  /* Change this to row to ensure icons stay in a row */
        flex-wrap: wrap;      /* Wrap the icons if needed */
        justify-content: center;  /* Center the icons */
        gap: 10px;            /* Space between icons */
        margin-bottom: 20px;
    }

    .footer-bottom-right p {
        margin: 0;
        color: white;
        
    }

    .footer-bottom-right .Icon {
        display: flex;
        text-decoration: none;
        color: white;
    }
    .sliding-panel {
        max-width: 95%; /* Take up more screen space on very small screens */
        left: 2.5%; /* Adjust the spacing from the sides */
        right: 2.5%;
        padding: 15px;
    }

    .sliding-panel-content {
        padding: 8px;
    }

    .close-btn-footer {
        font-size: 16px;
        top: 8px;
        right: 20px !important;
    }

    .sliding-panel h1 {
        font-size: 1.2rem; /* Further reduce font size on very small screens */
    }
}
@media (max-width: 480px){
    close-btn-footer{
	margin-right: 30px;
    }
}
