.admin-panel {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
  font-size: 28px;
  margin-bottom: 30px;
}

.logout-btn {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  float: right;
}

.blog-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.blog-form label {
  font-weight: bold;
  margin-top: 10px;
  display: block;
  color: #333;
}

.blog-form input,
.blog-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 20px;
  font-size: 14px;
}

textarea {
  height: 100px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.submit-btn,
.reset-btn {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  border-radius: 5px;
}

.submit-btn {
  background-color: #4caf50;
  color: white;
}

.reset-btn {
  background-color: #f44336;
  color: white;
}

.blog-list {
  margin-top: 40px;
}

.blog-item {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.blog-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-item h4 {
  margin: 0;
  color: #333;
}

.blog-actions button {
  padding: 8px 12px;
  margin-right: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}

.edit-btn {
  background-color: #2196f3;
  color: white;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}
/* Admin Page CSS Adjustments */

.service-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.service-form label {
  font-weight: bold;
  margin-top: 10px;
  display: block;
  color: #333;
}

.service-form input,
.service-form textarea,
.service-form select {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 20px;
  font-size: 14px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.submit-btn,
.reset-btn {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  border-radius: 5px;
}

.submit-btn {
  background-color: #4caf50;
  color: white;
}

.reset-btn {
  background-color: #f44336;
  color: white;
}

.service-list {
  margin-top: 40px;
}

.service-item {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.service-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-item h4 {
  margin: 0;
  color: #333;
}

.service-actions button {
  padding: 8px 12px;
  margin-right: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}

.edit-btn {
  background-color: #2196f3;
  color: white;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

