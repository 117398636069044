body{
    font-family: "Albert Sans", sans-serif;
    margin: 0;
    overflow-x: hidden;
}
.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    margin: 80px auto; /* Use auto to center the margin */
    width: 100%;
    max-width: 1200px; /* Add a max width for content centering */
    overflow: hidden; /* Prevent overflow */
    padding: 0 15px;
    box-sizing: border-box;
}

.about-title {
    position: relative;
    text-align: center;
    width: 100%;
}

.about-title h1 {
    padding: 0px 30px;
    font-size: 70px;
    font-weight: 300;
    color: #fff;
}

.about-title img {
    position: absolute;
    bottom: -55px;
    right: 330px;
    z-index: -1;
    height: 200%;
    width: 40%;
}

.about-sections {
    display: flex;
    flex-direction: column;
    gap: 80px;
    width: 100%;
    padding: 0 20px;
    align-items: center; /* Center content */
}


.about-right {
    display: flex;
    flex-direction: column;
    gap: 80px;
    width: 100%; /* Full width inside container */
    text-align: center; /* Center-align content */
}

.about-para {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 24px;
    font-weight: 500;
    padding: 0; /* Removed extra padding */
    width: 100%;
    text-align: center; /* Center text */
}

.about-para p {
    margin: 0;
    line-height: 1.6;
    font-weight: 200;
    overflow-wrap: break-word; /* Ensure text wraps inside the container */
}

.about-skills {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-between;
}

.about-skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.about-skill img {
    height: 60px;
    width: auto;
    margin-bottom: 10px;
    margin-top: 10px;
}

.about-skill p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
}

.about-skill:hover {
    transform: scale(1.25);
    transition: 0.3s;
}


.logos1 {
    overflow: hidden;
    padding: 60px 0;
    white-space: nowrap;
    position: relative;
    width: 100%;
}


.logos-slide1 {
    display: inline-flex; /* Ensure all logos are in one row */
    align-items: center;
    animation: 25s slide infinite linear;
}

.logos-slide1 img {
    height: 60px !important;
    width: auto !important;
    margin: 0 100px;
}

@keyframes slide1 {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
@media(min-width: 769px ) and (max-width: 1000px){
    .about {
        margin: 50px 30px;
        gap: 50px;
    }

    .about-title h1 {
        font-size: 50px;
        padding: 0 20px;
    }

    .about-title img {
        bottom: -35px;
        right: 50%;
        transform: translateX(50%);
        height: 150%;
        width: 60%;
    }

    .about-sections {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .about-left {
        display: flex;
        justify-content: center;
        width: 100%; /* Ensure the image container takes full width */
        margin-bottom: 20px; /* Add margin below image */
    }

    .about-left img {
        width: 100%;
        max-width: 300px; /* Ensure the image does not get too large */
        height: auto;
    }

    .about-right {
        gap: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-para {
        font-size: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        text-align: justify; /* Ensure better alignment for wrapping text */
    }

    .about-skills {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }

    .about-skill {
        flex-basis: 30%; /* Make sure the skills wrap properly */
        text-align: center;
    }

    .about-skill img {
        height: 60px;
        width: auto;
        margin: 10px 0;
    }
}

@media (max-width: 768px) {
    .about {
        gap: 50px;
    }

    .about-title h1 {
        font-size: 50px;
        padding: 0 20px;
    }

    .about-title img {
        bottom: -35px;
        right: 50%;
        transform: translateX(50%);
        height: 150%;
        width: 60%;
    }

    .about-sections {
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .about-right {
        gap: 50px;
    }

    .about-para {
        font-size: 20px;
    }

    .about-skills {
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .about-title h1 {
        font-size: 34px;
        margin-right: 40px; /* Remove excessive right margin */
        padding-top: 40px;
	padding-right: 31px;
        text-align: center; /* Center the title */
    }

    .about-para {
        font-size: 16px;
	font-weight: 250;
        margin-right: 40px; /* Remove margins */
        padding-right: 31px; /* Add padding for readability */
        overflow: visible;
        text-align: center; /* Ensure text is centered */
    }

    .about-skill img {
        height: 50px;
    }

    .about-skill p {
        font-size: 1rem;
    }

    .logos1 {
        overflow: hidden;
        margin-right: 10px; /* Remove right margin */
        width: 100%; /* Make sure logos take full width */
    }

    .about {
        padding-left: 80px; /* Add padding to prevent text from touching screen edges */
        width: 100%; /* Ensure the container takes full width of the screen */
        max-width: 100%; /* Prevent the container from exceeding the screen width */
        box-sizing: border-box; /* Include padding in the width calculation */
        overflow-x: hidden; /* Prevent horizontal scrolling */
    }
}
