/* Modal Container */
.modal-contact-container {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  background: white; /* Dark transparent background */
  color: white;
  padding: 30px;
  box-shadow: -8px 0 15px rgba(0, 0, 0, 0.7);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  transition: transform 0.3s ease;
}

/* Jobs specific styling */
.jobs-modal {
  max-width: 500px; /* Make the modal wider for Jobs */
  height: auto; /* Adjust the height for Jobs page */
  padding: 50px; /* Adjust padding for Jobs */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Fully center */
  background-color: #222; /* Darker background */
}

/* Form Layout */
.modal-contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.modal-contact-form label {
  color: black;
  font-size: 18px;
  font-weight: 500;
}
/* Form Layout */
.modal-contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.modal-contact-form label {
  color: black;
  font-size: 18px;
  font-weight: 500;
}

.modal-contact-form input,
.modal-contact-form textarea {
  border: 2px solid transparent;
  padding: 15px 20px;
  border-radius: 6px;
  background-color: rgba(140, 140, 140, 0.4);
  color: #a5a4a4;
  font-size: 16px;
  transition: border 0.3s ease;
}

.modal-contact-form input:focus,
.modal-contact-form textarea:focus {
  border-color: #025373; /* Highlight input on focus */
  outline: none;
}

.modal-contact-form input::placeholder,
.modal-contact-form textarea::placeholder {
  color: #888; /* Placeholder color */
}

.modal-contact-form textarea {
  height: 150px;
  resize: none;
}

/* Submit Button */
.modal-contact-submit {
  border: none;
  color: white;
  border-radius: 10px;
  background: #fa4b05;
  font-size: 20px;
  padding: 15px;
  cursor: pointer;
  transition: 0.3s;
}

.modal-contact-submit:hover {
  transform: scale(1.05);
  background: #e94606;
}

/* Close Button */
.close-btn {
  color: #fa4b05;
  background-color: #fa4b05;
  font-size: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-right: 100px !important;
}



/* Media Queries */
@media (max-width: 768px) {
  .modal-contact-container {
    max-width: 100%;
    padding: 20px;
  }

  .modal-contact-form input,
  .modal-contact-form textarea {
    font-size: 14px;
  }

  .modal-contact-submit {
    padding: 12px;
    font-size: 18px;
  }
}
