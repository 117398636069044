/* Main Container */
body {
  font-family: "Albert Sans", sans-serif;
}

.all-blogs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding: 80px 20px;
  background: #1a1a16;
  color: white;
  min-height: 100vh;
}
.more-tags {
  background-color: #ff8c00;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9rem;
  cursor: pointer;
}


/* Page Title */
.all-blogs h1 {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 40px;
  letter-spacing: 0.1em;
  position: relative;
}

.all-blogs h1:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #5583f7;
  border-radius: 5px;
}

/* Blog List Container */
.all-blog-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  width: 100%;
  max-width: 1200px;
}

/* Blog Post Card */
.all-blog-item {
  background: linear-gradient(45deg, #014169 30%, #024b79 50%, #035181 100%);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.all-blog-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

/* Blog Post Title */
.all-blog-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
}

/* Blog Post Date */
.all-blog-item p:first-of-type {
  font-size: 0.9rem;
  font-weight: 600;
  color: #a0a0a0;
  margin-bottom: 10px;
}

/* Blog Post Description */
.all-blog-item p:last-of-type {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #dcdcdc;
  transition: color 0.3s ease-in-out;
}

.all-blog-item:hover p:last-of-type {
  color: #ffffff;
}

/* Tag Container */
.tags-container {
  display: flex;
  flex-wrap: wrap;  /* Allows the tags to wrap when there's not enough space */
  gap: 10px;
  margin-bottom: 30px;
  justify-content: center;
}

/* Tag Buttons */
.tag {
  background-color: #03738C;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Prevents text from wrapping inside the tags */
}

.tag:hover {
  background-color: #025373;
}

.tag.active {
  background-color: #5583f7;
}

/* Blog Post Tags */
.blog-tags {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.blog-tag {
  background-color: #03738C;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9rem;
}

/* Pagination Container */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 10px;
}

/* Pagination Buttons */
.pagination-btn {
  background-color: #03738C;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-btn:hover,
.pagination-btn.active {
  background-color: #5583f7;
}

/* Responsive Design */
@media (max-width: 768px) {
  .all-blog-container {
    grid-template-columns: 1fr; /* Single column on smaller screens */
    gap: 30px;
  }

  .all-blog-item {
    padding: 20px;
  }

  .all-blog-title {
    font-size: 1.5rem;
  }

  .all-blog-item p {
    font-size: 1rem;
  }

  /* Adjust tag buttons for medium screens */
  .tags-container {
    flex-wrap: wrap; /* Allow the tags to wrap */
    gap: 8px;
  }

  .tag {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .all-blogs {
    padding: 60px 15px; /* Adjust padding for very small screens */
  }

  .all-blog-container {
    grid-template-columns: 1fr; /* Keep single column layout */
  }

  .all-blog-item {
    padding: 15px;
  }

  .all-blog-title {
    font-size: 1.3rem;
  }

  .all-blog-item p {
    font-size: 0.9rem;
  }

  /* Adjust tag buttons for small screens */
  .tags-container {
    gap: 6px;
  }

  .tag {
    padding: 6px 10px;
    font-size: 0.85rem; /* Smaller font size for the tags */
  }
}
