/* Main container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  /* Login box styling */
  .login-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  /* Form heading */
  .login-box h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    font-weight: 600;
  }
  
  /* Input fields */
  .login-input {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: border-color 0.3s;
  }
  
  .login-input:focus {
    border-color: #03738C;
    outline: none;
  }
  
  /* Submit button */
  .login-btn {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: #03738C;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-btn:hover {
    background-color: #025373;
  }
  
  /* Error message styling */
  .error-msg {
    color: #f44336;
    font-size: 14px;
    margin-top: 10px;
  }
  
