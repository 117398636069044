body{
    font-family: "Albert Sans", sans-serif;
}
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 40px 15px; /* Reduced padding to make the navbar shorter */
    height: 65px; /* Reduced height */
    box-sizing: border-box;
    background-color: transparent !important;
    z-index: 1000;
}
.navbar.navbar-transparent {
    background-color: transparent !important; /* Make navbar transparent on homepage and blog page */
  }

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 40px;
    font-size: 16px;
    overflow-x: hidden;
    margin-left: auto;
}

.nav-menu li {
    display: flex;
    flex-direction: column;
    gap: 3px;
    cursor: pointer;
}

.nav-menu li img {
    display: flex;
    margin: auto;
}

.nav-menu li:after {
    content: '';
    width: 0px;
    height: 2px;
    display: block;
    background: white;
    transition: 300ms;
}

.nav-menu li:hover:after {
    width: 100%;
}

.nav-logo {
    width: 180px; /* Adjusted size to fit the slightly shorter navbar */
    height: auto;
}

.anchor-link {
    text-decoration: none;
    color: white;
}

.nav-mob-open,
.nav-mob-close {
    display: none;
    cursor: pointer;
}

.language-switcher {
    display: flex;
    gap: 10px;
}

.language-switcher button {
    background: transparent;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 0;
    position: relative;
}

.language-switcher button.active {
    color: #fa4b05;
}

.language-switcher button:after {
    content: '';
    width: 0;
    height: 2px;
    display: block;
    background: white;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.get-in-touch {
    color: white;
    font-size: 1rem;
    border-radius: 50px;
    padding: 8px 16px;
    background: #fa4b05;
    cursor: pointer;
    display: inline-block;
    text-align: center;
}

.get-in-touch:hover {
    border: 2px solid white;
    transition: 0.11s;
}
@media (min-width: 1301px) {
    .nav-mob-open {
        display: none !important;
    }

    .nav-menu {
        display: flex !important;
        position: static;
        height: auto;
        width: auto;
        background-color: transparent;
        padding-top: 0;
    }
}


@media (max-width: 1300px) {
    .nav-menu {
        display: block;
        position: fixed;
        top: 0;
        right: -350px;
        height: 100%;
        width: 250px;
        flex-direction: column;
        background-color: #333;
        z-index: 1001;
        padding-top: 60px;
        transition: right 0.3s ease-in-out;
        margin-left: 0;
    }

    .nav-mob-open {
        display: block;
        width: 30px;
        height: 30px;
        z-index: 0;
    }

    .nav-menu.open {
        display: flex;
        right: 0;
    }

    .nav-mob-close {
        display: block;
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
    }
}


@media (max-width: 1200px) {
    .nav-menu {
        gap: 30px;
    }

    .nav-menu li {
        font-size: 14px;
    }

    .nav-logo {
        width: 160px;
    }
}

@media (max-width: 992px) {
    .nav-menu {
        gap: 20px;
    }

    .nav-menu li {
        font-size: 12px;
    }

    .nav-logo {
        width: 140px;
    }
}

@media (max-width: 768px) {
    .nav-menu {
        gap: 15px;
    }

    .nav-menu li {
        font-size: 14px;
    }

    .nav-logo {
        width: 120px;
    }
}
