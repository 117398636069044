body{
    font-family: "Albert Sans", sans-serif;
}
.hero {
    background: transparent;
    height: 600px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 20px;
    overflow: hidden;
    position: relative;
}

.text-container {
    padding-left: 100px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding-top: 150px;
    flex: 1;
}

.hero h2 {
    margin: 0;
    text-align: left;
    padding-top: 20px;
    color: rgb(159, 158, 158);
    font-weight: 200;
    
}

.hero h1 span {
    font-size: 3.5rem;
    background: linear-gradient(270deg, #ffffff 30% , #fa4b05 110%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    font-weight: 350;
}

.hero-contact {
    margin-top: 20px;
    color: white;
    font-size: 1.2rem;
    border-radius: 50px;
    padding: 10px 20px;
    background: #fa4b05;
    cursor: pointer;
    display: inline-block;
    text-align: left;
    width: max-content;
    text-decoration: none;
}

.hero-contact:hover {
    border: 2px solid white;
    transition: 0.11s;
}

.animation {
    height: 400px;
    width: 1000px;
    display: block;
    margin-left: auto;
    padding-top: 100px;
    padding-right: 70px;
    overflow: hidden;
    z-index: 1;
}

body {
    overflow-x: hidden;
}

.color {
    height: 1000px;
    width: 1000px;

    position: absolute;
    top: -30px;
    right: 60%;
    z-index: -1;
    opacity: 50%;
}

.color2 {
    height: 1000px;
    width: 1000px;

    position: absolute;
    top: -250px;
    left: 60%;
    z-index: -1;
    opacity: 70%;
}
.fixed-width {
    width: 600px; /* Adjust this width as needed */
    white-space: nowrap;
    overflow: visible;
    z-index: 1000;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-9-23 19:42:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
 @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  

  .text-shadow-pop-br {
	-webkit-animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite alternate both;
	        animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite alternate both;
            font-size: 8rem; /* Increase font size */
    position: relative; /* Set position relative to its container */
    z-index: 1;
    color: white;
    margin-top: 250px;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    margin-right: 50px; /* Space between the text and right edge */
}
.typing-text {
    font-size: 8rem; /* Increase font size */
    position: relative; /* Set position relative to its container */
    z-index: 1;
    color: white;
    font-weight: 100;
    margin-top: 200px;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    margin-right: 50px; /* Space between the text and right edge */
    margin-left: 40px;
}
@media (max-width: 1213px) {
    .hero h1 span {
        font-size: 2.2rem;
    }
    .hero h2 {
        font-size: 1.2rem;
        text-align: left;
        padding-top: 10px;
    }
    .typing-text {
        font-size: 5.5rem; /* Reduce font size for smaller screens */
        margin-top: 150px; /* Adjust top margin */
        margin-left: 50px; /* Adjust left margin for smaller screens */
        margin-right: 0;
    }
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 20px;
    }
    .text-container {
        padding-left: 20px;
        padding-top: 50px;
    }
    .hero h1 span {
        font-size: 2rem;
    }
    .hero h2 {
        font-size: 1.5rem;
        text-align: center;
        padding-top: 10px;
    }
    .hero-contact {
        margin: 20px auto;
    }
    .animation {
        height: 250px;
        padding-top: 50px;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
    }
    .color, .color2 {
        height: 800px;
        width: 800px;
        top: -100px;
        opacity: 50%;
    }
    .typing-text {
        font-size: 5rem; /* Reduce font size for smaller screens */
        margin-top: 50px; /* Adjust top margin */
        margin-left: 50px; /* Adjust left margin for smaller screens */
        margin-right: 0;
    }
}

@media (max-width: 500px) {
    .hero {
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 20px;
    }
    .text-container {
        padding-left: 10px;
        padding-top: 40px;
        align-items: center;
        text-align: center; /* Center align the text */
    }
    .hero h1 {
        width: 100%; /* Ensure full width */
        text-align: center; /* Center align the h1 text */
    }
    .hero h1 span {
        font-size: 1.8rem;
        padding-left: 0;
    }
    .hero h2 {
        font-size: 1.3rem;
        text-align: center;
        padding-top: 10px;
    }
    .hero-contact {
        margin: 20px auto;
        font-size: 1rem;
    }
    .animation {
        height: 200px;
        padding-top: 40px;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
    }
    .color, .color2 {
        height: 600px;
        width: 600px;
        top: -50px;
        opacity: 50%;
    }
    .typing-text {
        font-size: 3.5rem;
        margin-top: 20px; /* Adjust top margin */
        margin-left: 140px; /* Further adjust left margin for small screens */
        margin-right: 0;
        z-index: 1000;
        overflow: visible;
    }
    .fixed-width {
        width: 500px; /* Adjust this width as needed */
        white-space: nowrap;
        overflow: visible;
        z-index: 1000;
    }
}
