body{
  font-family: "Albert Sans", sans-serif;
}
.clients {
    padding: 50px 0;
    position: relative;
}

.clients-section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: flex-start;
}

.clients-text {
    flex: 1;
    padding-right: 50px;
}

.clients-text h1 {
    font-size: 38px;
    font-weight: 800;
    background: linear-gradient(270deg, #fff 70%, #fa4b05 110%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    text-align: left;
}

.clients-text p {
    font-size: 1.2rem;
    color: #ffffff;
    line-height: 1.5;
}

.clients-categories {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 10px;
}

.category-tag {
    background-color: rgba(250, 75, 5, 0.9);
    color: white;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 1rem;
    white-space: nowrap;
}

/* Carousel container to hold the scrolling logos */
.clients-carousel {
    position: relative;
    overflow: hidden; /* Hide the overflow */
    margin-top: 40px;
    width: 100%; /* Full width */
}

/* Clients container holds the logos and animates the scrolling */
.clients-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
    animation: scroll 20s linear infinite; /* Smooth scrolling */
}

/* Individual logo styles */
@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 60px 0;
    background: transparent;
    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  .logos:before {
    left: 0;
    background: transparent;
  }
  
  .logos:after {
    right: 0;
    background: transparent;
  }
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: inline-block;
    animation: 25s slide infinite linear;
  }
  
  .logos-slide img {
    vertical-align: middle;
  }
  .Logo3{
    margin: 0 70px;
    width: 50px;
    top: 0;
    height: 50px;
  }
  .Logo4{
    margin: 0 70px;
    width: 50px;
    top: 0;
    height: 50px;
  }
  .Logo5{
    margin: 0 70px;
    width: 120px;
    height: 70px;
    top: 0;
  }
  .Logo6{
    margin: 0 70px;
    width: 100px;
    height: 100px;
    top: 0;
    
  }
  
