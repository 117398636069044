*{
    margin: 0;
    padding: 0;
}
body{
    height: 100vh;
    background: #1a1a16;
    color: white;
    font-family: "Albert Sans", sans-serif;
    overflow-x: hidden;
    background-image: radial-gradient(closest-side at 45% 50%, rgba(7, 101, 111, 0.3), transparent), 
                      radial-gradient(closest-side at 55% 40%,rgba(3, 115, 140, 0.3), transparent);
    background-repeat: no-repeat;
    background-size: 900px 800px, 1000px 1000px;
    background-position: left, bottom right;
    background-position-x: -300px, 1000px;
    background-position-y: 100px, -260px;
    color: white;
    z-index: 2000;
}


