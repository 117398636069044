/* Main Container */
body {
  font-family: "Albert Sans", sans-serif;
}

.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding: 80px 20px;
  background: linear-gradient(45deg, #014169 30%, #024b79 50%, #035181 100%);
  color: white;
  min-height: 100vh;
}

/* Blog Title */
.blog-title h1 {
  font-size: 50px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 40px;
  letter-spacing: 0.1em;
  position: relative;
}

.blog-title h1:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #fa4b05;
  border-radius: 5px;
}

/* Blog Container */
.blog-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 50px;
  width: 100%;
  max-width: 1200px;
}

/* Blog Post Style */
.blog-post {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.05);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  cursor: pointer;
}

.blog-post:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

/* Blog Post Header */
.blog-post h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
}

.blog-post h2:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: #fa4b05;
  border-radius: 5px;
}

/* Blog Post Description */
.blog-post p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #dcdcdc;
  text-align: center;
  margin-top: 20px;
  flex-grow: 1;
}

/* Read More Button */
.read-more-btn {
  font-size: 1rem;
  color: #1c98d1;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
  margin-top: 20px;
  text-align: center;
  display: inline-block;
}

.read-more-btn:hover {
  color: #1985b7;
  text-decoration: underline;
}

/* View All Blogs Button */
.view-all-blogs {
  margin-top: 15px;
}

.view-all-btn {
  font-size: 18px;
  color: white;
  background-color: #fa4b05;
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 40px;
  transition: 0.3s;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}

.view-all-btn:hover {
  background-color: #bc3602;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Media Queries */
@media (max-width: 768px) {
  .blog-container {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .blog-post {
    padding: 30px;
  }

  .blog-post h2 {
    font-size: 1.5rem;
  }

  .blog-post p {
    font-size: 1rem;
  }
}

/* Media query for 770px to 1057px: Set blogs in a column */
@media (min-width: 770px) and (max-width: 1057px) {
  .blog-container {
    grid-template-columns: 1fr;  /* Force one column layout */
    gap: 30px;
  }

  .blog-post {
    padding: 30px;
  }

  .blog-post h2 {
    font-size: 1.6rem;
  }

  .blog-post p {
    font-size: 1.05rem;
  }
}
