body{
  font-family: "Albert Sans", sans-serif;
}
.whyus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    max-width: 1200px; /* Limit the max-width for better centering on large screens */
    background: #1e1e1f; /* White background as requested */
    padding: 20px;
    border-radius: 10px;
  }
  
  .whyus-title {
    font-size: 50px;
    font-weight: 300;
    margin-bottom: 50px;
    color: #ffffff; /* Black text */
    text-align: center;
  }
  
  /* Flex container for the three sections */
  .whyus-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start; /* Align sections to the top for even spacing */
    width: 100%;
    gap: 20px;
    flex-wrap: wrap; /* Allow sections to wrap on smaller screens */
    padding: 20px 0;
  }
  
  /* Individual section container */
  .whyus-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 300px; /* Limit the width of each section */
    margin: 20px 0; /* Add vertical margin */
  }
  
  .whyus-section h2 {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #ffffff; /* Black text for headings */
  }
  
  .whyus-section p {
    margin-top: 10px;
    font-size: 1rem;
    color: #ffffff; /* Slightly lighter text for description */
    line-height: 1.6; /* Improve readability */
  }
  
  /* Adjust the animation size */
  .whyus-animation {
    width: 200px;
    height: 200px;
    margin-bottom: 20px; /* Space between animation and text */
  }
  
  /* Responsive Design Adjustments */
  
  /* Tablet and below */
  @media (max-width: 1024px) {
    .whyus-content {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
  
    .whyus-section {
      max-width: 90%;
    }
  }
  
  /* Mobile devices */
  @media (max-width: 768px) {
    .whyus-title {
      font-size: 2rem;
    }
  
    .whyus-section h2 {
      font-size: 1.3rem;
    }
  
    .whyus-section p {
      font-size: 0.9rem;
    }
  
    .whyus-animation {
      width: 120px; /* Adjust animation size on smaller screens */
      height: 120px;
    }
  }
  