/* Main Container */
body {
  font-family: "Albert Sans", sans-serif;
  color: #ffffff; /* Set default text color to white */
}

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding: 80px 20px;
  background: linear-gradient(45deg, #014169 30%, #024b79 50%, #035181 100%);
  color: white;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Page Title */
.services-title h1 {
  font-size: 50px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 40px;
  letter-spacing: 0.1em;
  position: relative;
}

.services-title h1:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #5583f7;
  border-radius: 5px;
}

/* Service Cards */
.services-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Ensure 3 equal-width columns */
  gap: 50px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 100px;
}
.services-format:nth-child(1),
.services-format:nth-child(2),
.services-format:nth-child(3),
.services-format:nth-child(4),
.services-format:nth-child(5),
.services-format:nth-child(6) {
  grid-column: span 1; /* Ensure each service spans only 1 column */
}


.services-format {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  overflow: hidden;
}

.services-format:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

/* Service Header */
.service-header {
  z-index: 1;
  text-align: center;
}

.service-header h2 {
  font-size: 1.8rem;
  font-weight: 300;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center; /* Ensure the service header is centered */
}

.service-header h2:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: #5583f7;
  border-radius: 5px;
}

/* Description */
.services-format p {
  margin-top: 20px;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #ffffff;
  text-align: center;
  transition: color 0.3s ease-in-out;
  font-family: "Albert Sans", sans-serif;
}

.services-format:hover p {
  color: #ffffff;
}

/* Sliding Service Panel */
.service-details-overlay {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 700px;
  height: 100%;
  background: white;
  color: white;
  padding: 40px;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
  display: flex;
  flex-direction: column;
}

.service-details-overlay.show {
  transform: translateX(0);
}

.service-details {
  flex-grow: 1;
  overflow-y: auto;
  color: black;
  word-wrap: break-word;
}

.service-details h2 {
  color: black;
}


/* Styling for the table */
.service-details table {
  width: 100%;
  border-collapse: collapse; /* Use collapse to eliminate spacing between cells */
  margin-top: 20px;

  border: none; /* Remove all borders initially */
}

/* Styling for table headers and data cells */
.service-details th,
.service-details td {
  border-bottom: 1px solid #ddd; /* Only show horizontal borders */
  padding: 12px 15px;
  text-align: center; /* Ensure all content is horizontally centered */
  vertical-align: middle; /* Ensure all content is vertically centered */
  color: #ffffff;  /* Ensure text is white for headers and cells */
}

/* Header styling */
.service-details th {
  background-color: #ffffff;
  color: #000000; /* Change header text color to black */
  font-weight: bold;
  text-align: center; /* Ensure header text is centered */
  border-bottom: 2px solid #ddd; /* Stronger horizontal border for the header */
}

/* Data cell styling */
.service-details td {
  color: #000000; /* Ensure table data text is black */
  text-align: center;
  vertical-align: middle; /* Ensure table data is vertically centered */
}

/* Alternating row background */


/* Hover effect for rows */
.service-details tr:hover {
  
  transform: scale(1.02); /* Slight scaling on hover */
  transition: 0.3s;
}
/* Ensures table content is centered */
.service-details tr td, 
.service-details tr th {
  display: table-cell; /* Force table-cell layout to ensure centering */
  vertical-align: middle; /* Vertical alignment */
  text-align: center; /* Horizontal alignment */
}

/* Remove border on last column and last row */
.service-details th:last-child,
.service-details td:last-child {
  border-right: none;
}
.service-details th,
.service-details td {
  border-right: none; /* Remove vertical borders */
  border-left: none;  /* Remove vertical borders */
}

.service-details tr:last-child td {
  border-bottom: none;
}



/* Close Button */
.close-btn {
  background: none;
  border: none;
  color: #fa4b05 ;
  font-size: 24px;
  position: fixed; /* Always keep it fixed and visible */
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1000;
  margin-right: 15px !important;
}



/* Contact Us Button */
.contact-us-btn {
  border: none;
  margin-left: 10px;
  margin-bottom: 20px;
  color: white;
  border-radius: 40px;
  background: #6bbec7;
  font-size: 22px;
  padding: 15px 40px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 20px;
  align-self: center;
}

.contact-us-btn:hover {
  transform: scale(1.05);
  transition: 0.3s;
}

.service-details-overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-details-overlay .service-details {
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  padding-right: 20px;
  padding-left: 20px;
}

.service-details img {
  max-width: 100%;
  height: auto;
}


/* Media Queries */
@media (max-width: 768px) {
  .services-container {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .services-format {
    padding: 30px;
  }

  .service-header h2 {
    font-size: 1.5rem;
  }

  .services-format p {
    font-size: 1rem;
  }

  /* Sliding Service Panel */
  .service-details-overlay {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    left: 0;
  }

  .service-details {
    max-height: calc(100vh - 40px);
    padding-right: 15px;
    padding-left: 15px;
  }

  .close-btn {
    right: 10px;
    top: 10px;
    font-size: 18px;
    margin-right: 45px !important;
  }

  .contact-us-btn {
    font-size: 20px;
    padding: 12px 35px;
  }
}

@media (max-width: 480px) {
  .service-details-overlay {
    padding: 15px;
    max-width: 100%;
  }

  .service-details {
    max-height: calc(100vh - 30px);
  }

  .close-btn {
    top: 10px;
    right: 10px;
    font-size: 18px;
    margin-right: 45px !important;
  }

  .contact-us-btn {
    font-size: 20px;
    
  }
  .services-title h1{
    font-size: 40px;
  }
}
