body {
    font-family: "Albert Sans", sans-serif;
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    margin: 80px 170px;
}

.contact-title {
    position: relative;
}

.contact-title h1 {
    padding: 0px 30px;
    font-size: 80px;
    font-size: 80px;
    font-weight: 300;
    color: #fff
}

.contact-section {
    display: flex;
    gap: 150px;
}

.contact-left {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contact-left h1 {
    font-size: 80px;
  font-weight: 300;
  background: linear-gradient(270deg, #fff 70%, #fa4b05 110%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-left p {
    max-width: 550px;
    color: #d8d8d8;
    font-size: 20px;
    line-height: 35px;
}

.contact-details {
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #d8d8d8;
    font-size: 22px;
}

.contact-detail {
    display: flex;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    transition: 0.3s ease;
}

.contact-detail:hover {
    color: #246b97;
    transition: 0.3s;
}

.contact-right {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 30px;
}

.contact-right label {
    color: #d8d8d8;
    font-size: 22px;
    font-weight: 500;
}

.contact-right input {
    border: none;
    width: 500px;
    height: 50px;
    padding-left: 20px;
    border-radius: 4px;
    background: #32323c;
    color: #A0A0A0;
    font-family: Outfit;
    font-size: 20px;
}

.contact-right textarea {
    width: 475px;
    padding: 25px;
    border: none;
    border-radius: 4px;
    background: #32323c;
    color: #A0A0A0;
    font-family: Outfit;
    font-size: 20px;
    resize: none;
}

.contact-submit {
    border: none;
    color: white;
    border-radius: 20px;
    background: #fa4b05;
    font-size: 20px;
    padding: 15px 40px;
    margin-bottom: 50px;
    cursor: pointer;
    transition: 0.3s;
}

.contact-submit:hover {
    transform: scale(1.1);
    transition: 0.3s;
}

/* Media Queries for small screens */
@media (max-width: 768px) {
    .contact {
        gap: 50px;
        margin: 50px 20px;
    }

    .contact-title h1 {
        font-size: 40px;
        text-align: center;
    }

    .contact-section {
        flex-direction: column;
        gap: 40px;
    }

    .contact-left h1 {
        font-size: 36px;
    }

    .contact-left p {
        font-size: 16px;
        line-height: 28px;
    }

    .contact-right input, .contact-right textarea {
        width: 100%;
    }

    .contact-submit {
        width: 100%;
        padding: 15px 0;
        font-size: 20px;
    }

    .contact-detail {
        gap: 20px;
    }
}

/* Media Queries for larger screens, preventing cropping */
@media (min-width: 769px) and (max-width: 930px) {
    .contact {
        margin: 50px 20px; /* Reduce left and right margins to avoid cropping */
    }

    .contact-section {
        flex-direction: column; /* Stack contact left and right sections */
        align-items: center;
        gap: 40px; /* Reduce gap to make better use of space */
    }

    .contact-right input, .contact-right textarea {
        width: 100%; /* Make inputs full width for better alignment */
    }

    .contact-left h1 {
        font-size: 48px; /* Reduce font size for the title */
    }

    .contact-left p {
        font-size: 18px; /* Adjust text size */
        text-align: center;
    }

    .contact-right input, .contact-right textarea {
        width: 100%; /* Ensure input and textarea stretch across available space */
    }

    .contact-submit {
        width: 100%;
        padding: 15px 0;
        font-size: 18px;
    }
}

@media(min-width: 930px) and (max-width: 1000px) {
    .contact-right input {
        width: 400px; /* Set width to ensure it fits within larger screen size */
    }

    .contact-right textarea {
        width: 400px; /* Same for textarea */
    }

    .contact-title {
        margin-left: 0; /* Center the title */
    }

    .contact-section {
        gap: 100px; /* Add some breathing room between sections */
    }
}
