body{
  font-family: "Albert Sans", sans-serif;
}
.faq-page {
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-page h1 {
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    color: #fff;
  }
  
  .faq-highlight {
    color: #3e6e74; /* Color similar to the one used in the image */
  }
  
  .faq-list {
    margin-top: 30px;
  }
  
  .faq-item {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .faq-question {
    cursor: pointer;
    padding: 15px;
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  
  .faq-question span {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #3e6e74; /* Use the same color as the FAQ title */
  }
  
  .faq-question.open span {
    color: #3e6e74;
  }
  
  .faq-answer {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease; /* Smooth transition for max-height and opacity */
    opacity: 0;
  }
  
  .faq-answer-content {
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
  }
  
  .faq-question.open + .faq-answer {
    opacity: 1;
  }
  
  

  
