/* Job Titles Panel */
body{
  font-family: "Albert Sans", sans-serif;
}
.no-jobs {
  font-size: 1.5rem;
  color: #ff4747;
  text-align: center;
  margin-top: 20px;
}
 
.jobs-container-slide-in {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 800px;
  height: 100%;
  background: white;
  color: #025373;
  padding: 40px;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out;
  z-index: 1001;
  overflow-y: auto;
  box-sizing: border-box;
  overflow-x: hidden;
}

.jobs-container-slide-in h1{
  margin-top: 40px;
  color: black;
}
.jobs-container-slide-in p{
  margin-top: 50px;
  color: black;
}
.jobs-container-slide-in h2{
  margin-top: 40px;
  color: black;
}

.jobs-container-slide-in.show {
  transform: translateX(0); /* Slide fully into view */
}

/* Job Description Panel */
.job-details-overlay {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 800px;
  height: 100%;
  background: white;
  color: black;
  padding: 40px;
  box-shadow: -8px 0 20px rgba(0, 0, 0, 0.7);
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out;
  z-index: 2000;
  overflow-y: auto; /* Enable scrolling */
  box-sizing: border-box;
}
.job-details-overlay h2{
  margin-top: 50px;
  color: black;
}

.job-details-overlay.show {
  transform: translateX(0); /* Slide fully into view */
}

.jobs-list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: black;
}

.job-item {
  background: rgba(255, 255, 255, 0.05);
  padding: 30px;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  overflow: hidden;
  word-wrap: break-word;
  color: black;
}

.job-item:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

.job-item h3 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffffff;
  color: black;
}

.job-details h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: black;
}

.job-details p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: black;
}

.contact-us-btn {
  margin-top: 20px;
  border: none;
  color: #fa4b05;
  border-radius: 50px;
  background: #fa4b05 !important; 
  font-size: 22px;
  padding: 15px 40px;
  cursor: pointer;
  transition: 0.3s;
}

.contact-us-btn:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
/* Contact Overlay */
.contact-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.contact-form {
  background: white;
  padding: 40px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  color: black; /* Adjusted this for better text visibility */
}

.contact-form h2 {
  margin-bottom: 20px;
  text-align: center;
  color: black; /* Make sure the heading is visible */
}


.contact-form label {
  display: block;
  margin-bottom: 10px;
  color: #333333; /* Changed to a darker color for better visibility */
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  background-color: rgba(143, 143, 143, 0.4); /* Lighter input background */
  color: #333333; /* Darker text for better readability */
  resize: none;
}

.contact-form button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #fa4b05;
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.3s;
}

.contact-form button:hover {
  transform: scale(1.05);
}

/* Close Button */
.close-btn-jobs {
  position: absolute;
  top: 20px;
  margin-left: 720px !important;
  background: none;
  border: none;
  color: #fa4b05 !important;
  font-size: 24px;
  cursor: pointer;
}

.close-btn-join {
  position: fixed; /* Ensures it stays fixed relative to the viewport */
  top: 20px; /* Adjust this value to suit your layout */
  right: 20px; /* Keep it aligned to the right */
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  z-index: 4000; /* Ensure it's above other elements */
}

.close-btn-join:hover {
  color: #ff4747;
}

.close-btn-contact {
  position: absolute;
  top: 100px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 355px;
}
/* Join Us Overlay */
.join-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.join-form {
  background: white;
  padding: 40px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  color: white;
  margin-top: 50px; /* Add some space above the form */
}

.join-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.join-form label {
  display: block;
  margin-bottom: 10px;
  color: black;
}

.join-form input,
.join-form textarea
{
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  background-color: rgba(143, 143, 143, 0.4);
  color: black;
  resize: none;
}
.join-form select{
  width:103%;
  max-width: 900px;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  background-color: rgba(143, 143, 143, 0.4);
  color: black;
  resize: none;
}

.join-form button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #fa4b05;
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.3s;
}

.join-form button:hover {
  transform: scale(1.05);
}
/* Join Us Button */
.join-us-btn {
  margin-top: 20px;
  border: none;
  color: white;
  border-radius: 50px;
  background: #fa4b05; /* Same background as your 'contact-us-btn' */
  font-size: 22px;
  padding: 15px 40px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  text-align: center;
}

.join-us-btn:hover {
  transform: scale(1.1);
  background: #fa4b05; /* Darker shade on hover */
  transition: 0.3s ease-in-out;
}

/* Media Queries */
@media (max-width: 768px) {
  .jobs-container-slide-in,
  .job-details-overlay {
    max-width: 100%;
  }

  .job-item h3 {
    font-size: 1.6rem;
  }

  .job-details h2 {
    font-size: 1.8rem;
  }
  .join-form {
    margin-top: 80px; /* Smaller space for mobile */
  }
  .close-btn-join {
    top: 15px;  /* Adjust for smaller screens */
    right: 15px; /* Slightly reduce the margin */
  }
  .close-btn-jobs {
    right: 10px !important;
    top: 10px !important;
    font-size: 18px !important;
    padding-right: 0px !important;
    margin-right: 10px !important;
  }
}

@media (max-width: 480px) {
  .job-item h3 {
    font-size: 1.4rem;
  }

  .job-details h2 {
    font-size: 1.6rem;
  }

  .job-details p {
    font-size: 1rem;
  }
  .join-form {
    margin-top: 60px; /* Smaller space for mobile */
  }
  .close-btn-join {
    top: 10px; /* Further adjust for very small screens */
    right: 10px;
    font-size: 18px; /* Slightly smaller font size for mobile */
  }
  .close-btn-jobs {
    margin-right: 0px  !important;
  }
}
